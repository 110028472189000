
<script lang="jsx">
import { defineComponent } from 'vue'
import pageSkeletonConfig from './pageSkeletonConfig.js'
import skeletonItem from './skeletonItem.vue'
export default defineComponent({
  name: 'ListSkeletonV2',
  components: { skeletonItem },
  props: {
    // 列表公共 productList 
    // 收藏 wishList
    // 店铺 storePage
    // 凑单列表 addItem
    // 推荐弹窗 recommendDrawer
    // sheinPicks页用 sheinPicks
    // buybox弹窗 buyboxCom
    page: {
      type: String,
      default: 'productList'
    },
  },
  render({ $props: props, $data: data }) {
    const renderConfig = pageSkeletonConfig[props.page]
    if (renderConfig) {
      return (
        <div 
          class={[
            'list-skeleton-container',
            data.class,
            data.staticClass,
          ]}
          style={[data.style, data.staticStyle]}
        >
          {
            renderConfig.map((item) => {
              const style = item.style ?? {}
              return (
                <skeletonItem style={style} type={item.type} number={item.number}></skeletonItem>
              )
            })
          }
        </div>
      )
    } else {
      return ''
    }
  },
})

</script>

<style lang="less" scoped>
.list-skeleton-container {
  z-index: @zindex-modal-mask;
  transform: translate3D(0, 0, 9999px);
  background-color: #fff;
}
</style>
