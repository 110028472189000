<script lang="jsx">
import { defineComponent } from 'vue'
import { SSkeleton } from '@shein-aidc/sui-skeleton/mobile'
const { SSkeletonItem } = SSkeleton

export default defineComponent({
  name: 'SkeletonItem',
  components: { SSkeleton, SSkeletonItem },
  props: {
    // tab tab列
    // line 分割线
    // picNav 图文导航 
    // filter 筛选栏
    // cloudTag 标签云
    // goodsOne 单列商品
    // goodsDouble 双列商品
    // goodsDoubleCategory 双列商品-收藏品类视图
    type: {
      type: String,
      default: ''
    },
    // 商品项骨架屏使用，渲染几个
    number: {
      type: [Number, String],
      default: 4
    }
  },
  render({ $props: props, $data: data }) {
    const line = () => {
      return (
        <div class="skeleton-item-line">
          <SSkeleton animated>
            {{
              template: () => (
                <SSkeletonItem
                  style="width:100%;height:.266rem;"
                  variant="text"
                />
              )
            }}
          </SSkeleton>
        </div>
      )
    }

    const tab = () => {
      return (
        <div class="skeleton-item-tab">
          <SSkeleton animated>
            {{
              template: () => (
                <>
                  {
                    Array.from({ length: 6 }, () => (
                      <SSkeletonItem
                        style="width:2rem;height:.64rem;margin-right:.213rem;"
                        variant="text"
                      />
                    ))
                  }
                </>
              )
            }}
          </SSkeleton>
        </div>
      )
    }

    const picNav = () => {
      return (
        <div class="skeleton-item-picNav">
          <SSkeleton animated>
            {{
              template: () => (
                <>
                  {
                    Array.from({ length: 6 }, () => (
                      <div class="skeleton-item-picNav-item">
                        <SSkeletonItem 
                          style="width:60px;height:60px;border-radius:50%;" 
                          variant="text"
                        />
                        <SSkeletonItem 
                          style="width:64px;height:16px;border-radius:16px;margin-top:8px;" 
                          variant="text"
                        />
                      </div>
                    ))
                  }
                </>
              )
            }}
          </SSkeleton>
        </div>
      )
    }

    const filter = () => {
      return (
        <div class="skeleton-item-filter">
          <SSkeleton animated>
            {{
              template: () => (
                <>
                  {
                    Array.from({ length: 4 }, () => (
                      <SSkeletonItem
                        style="width:2.02rem;height:.64rem;"
                        variant="text"
                      />
                    ))
                  }
                </>
              )
            }}
          </SSkeleton>
        </div>
      )
    }

    const cloudTag = () => {
      return (
        <div class="skeleton-item-cloudTag">
          <SSkeleton animated>
            {{
              template: () => (
                <>
                  {
                    Array.from({ length: 5 }, () => (
                      <SSkeletonItem
                        style="width:1.97rem;height:.72rem;margin-right:.213rem;"
                        variant="text"
                      />
                    ))
                  }
                </>
              )
            }}
          </SSkeleton>
        </div>
      )
    }

    const goodsOne = () => {
      return (
        <div class="skeleton-item-goodsOne">
          <SSkeleton animated>
            {{
              template: () => (
                <>
                  {
                    Array.from({ length: props.number }, () => (
                      <div class="goods-container">
                        <SSkeletonItem
                          style="width:2.56rem;flex:0 0 2.56rem;height:3.46rem;border-radius:.06rem;"
                          variant="text"
                        />

                        <div class="goods-info">
                          <div class="goods-info__top">
                            <SSkeletonItem
                              style="width:6.48rem;height:.53rem;"
                              variant="text"
                            />
                            <SSkeletonItem
                              style="width:3.2rem;height:.53rem;"
                              variant="text"
                            />
                          </div>

                          <div class="goods-info__bottom">
                            <SSkeletonItem
                              style="width:5.173rem;height:.37rem;"
                              variant="text"
                            />
                            <SSkeletonItem
                              style="width:.53rem;height:.53rem;"
                              variant="text"
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </>
              )
            }}
          </SSkeleton>
        </div>
      )
    }

    const goodsDouble = () => {
      return (
        <div class="skeleton-item-goodsDouble">
          <SSkeleton animated>
            {{
              template: () => (
                <>
                  {
                    Array.from({ length: props.number }, () => (
                      <div class="goods-container">
                        <SSkeletonItem
                          style="width:4.77rem;height:6.4rem;border-radius:.11rem"
                          variant="text"
                        />

                        <div class="goods-info">
                          <SSkeletonItem
                            style="width:4.35rem;height:.37rem"
                            variant="text"
                          />

                          <div class="goods-info__top">
                            <SSkeletonItem
                              style="width:3.28rem;height:.53rem"
                              variant="text"
                            />
                            <SSkeletonItem
                              style="width:.96rem;height:.64rem;border-radius:.32rem" 
                              variant="text"
                            />
                          </div>

                          <div class="goods-info__bottom">
                            <SSkeletonItem
                              style="width:1.36rem;height:.37rem;border-radius:0.11rem"
                              variant="text"
                            />
                            <SSkeletonItem
                              style="width:1.36rem;height:.37rem;border-radius:0.11rem"
                              variant="text"
                            />
                            <SSkeletonItem
                              style="width:1.36rem;height:.37rem;border-radius:0.11rem"
                              variant="text"
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </>
              )
            }}
          </SSkeleton>
        </div>
      )
    }

    const goodsDoubleCategory = () => {
      return (
        <div class="skeleton-item-goodsDoubleCategory">
          <SSkeleton animated>
            {{
              template: () => (
                <>
                  {
                    Array.from({ length: props.number }, () => (
                      <div class="goods-container">
                        <SSkeletonItem
                          style="width:3.36rem;height:3.36rem;border-radius:.11rem"
                          variant="text"
                        />

                        <div class="goods-info">
                          <div class="goods-info__top">
                            <SSkeletonItem
                              style="width:1.7rem;height:.53rem"
                              variant="text"
                            />
                            <SSkeletonItem
                              style="width:.59rem;height:.53rem;float:right;"
                              variant="text"
                            />
                          </div>
                          <SSkeletonItem
                            style="width:3.36rem;height:.37rem"
                            variant="text"
                          />
                        </div>
                      </div>
                    ))
                  }
                </>
              )
            }}
          </SSkeleton>
        </div>
      )
    }


    // line 分割线
    // picNav 图文导航 
    // filter 筛选栏
    // cloudTag 标签云
    // goodsOne 单列商品
    // goodsDouble 双列商品
    // goodsDoubleCategory 双列商品-收藏品类视图
    const typeRenderMap = new Map([
      ['line', line],
      ['picNav', picNav],
      ['filter', filter],
      ['cloudTag', cloudTag],
      ['goodsOne', goodsOne],
      ['goodsDouble', goodsDouble],
      ['goodsDoubleCategory', goodsDoubleCategory],
      ['tab', tab]
    ])

    const typeRes = props.type && typeRenderMap.get(props.type)()
    return (
      <div 
        class={[
          'skeleton-item',
          data.class,
          data.staticClass
        ]}

        style={[
          data.style,
          data.staticStyle
        ]}
      >
        { typeRes }
      </div>
    )
  },
})

</script>

<style lang="less" scoped>
.skeleton-item {
  background: #fff;
  line-height: 1;
}

.skeleton-item-tab {
  .flexbox();
  .align-center();
  padding-left: .32rem;
  height: 1.12rem;

  :deep(.sui-skeleton__wrap) {
    .flexbox();
    .align-center();
    flex-wrap: nowrap;
  }
}

.skeleton-item-picNav {
  .flexbox();
  .align-center();

  flex-wrap: nowrap;
  padding: 12px;
  :deep(.sui-skeleton__wrap) {
    white-space: nowrap;
  }
  &-item {
    display: inline-flex;
    flex-direction: column;
    margin-right:12.75px;
    align-items: center;
  }
}
.skeleton-item-filter {
  .flexbox();
  .align-center();
  height: 1.173rem;

  :deep(.sui-skeleton__wrap) {
    .flexbox();
    .align-center();
    justify-content: space-around;
  }
}

.skeleton-item-cloudTag {
  .flexbox();
  flex-wrap: nowrap;

  padding-left: .16rem;
  height: .934rem;

  :deep(.sui-skeleton__wrap) {
    .flexbox();
    .align-center();
    justify-content: space-around;
  }
}

.skeleton-item-goodsOne {
  .goods-container {
    .flexbox();
    padding: 0 .32rem .746rem .32rem;
  }

  .goods-info {
    .flexbox();
    flex-direction: column;
    justify-content: space-between;

    padding-left: .32rem;

    &__top {
      &>div:first-child {
        margin-bottom: .32rem;
      }
    }

    &__bottom {
      .flexbox();
      .align-center();
      justify-content: space-between;

    }
  }
}

.skeleton-item-goodsDouble {
  padding: 0 .16rem;

  :deep(.sui-skeleton__wrap) {
    .flexbox();
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .goods-container {
    padding-bottom: .427rem;
  }
  .goods-info {
    padding: .16rem .213rem 0 .213rem;

    &__top {
      padding: .106rem 0 .213rem 0;
      .flexbox();
      justify-content: space-between;
    }

    &__bottom {
      .flexbox();
      justify-content: space-between;

    }
  }
}

.skeleton-item-goodsDoubleCategory {
  :deep(.sui-skeleton__wrap) {
    padding: 0 0.16rem;
    .flexbox();
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>
